{
  "notFound": "404 - Not Found",
  "traducteur": "Переклад Oleksandr Agarkov",
  "contact-us": "",
  "head": {
    "meta": {
      "description": "Данный тест состоит из вопросов на тему алкоголя, секса и наркотиков.",
      "title": "Purity Test"
    }
  },
  "menu": {
    "test-purete": "Purity Test",
    "random-shitpost": "random-shitpost.com"
  },
  "home-page": {
    "title": "Purity Test",
    "subtitle": "Данный тест состоит из вопросов на тему алкоголя, секса и наркотиков.",
    "intro-text": {
      "line-1": " Некоторые вопросы могут быть оскорбительными для Вас.",
      "line-2": "Если это так, то не продолжайте дальше и закройте вкладку с этим сайтом...",
      "line-3": ""
    },
    "beware": "Пожалуйста, не нажимайте кнопку назад во время теста! (Тест аннулируется и придется проходить заново) ... Удачи !!",
    "ad-block": {
      "title": "⬇️Découvre notre nouveau site inutile⬇️"
    },
    "begin-griffor-btn": "👉 Purity test классический",
    "begin-boudah-btn": "Альтернативная версия",
    "continue-btn": "возобновить",
    "ad-warning": {
      "warning": "☝️ Attention, si une publicité s'ouvre, faites 'retour' ☝️",
      "our-test": "(notre test est et sera",
      "always": "toujours",
      "free": "gratuit) 😇"
    },
    "already": "прошло уже ",
    "tests-made": " тестов! 🎉"
  },
  "possibleAnswers": {
    "oui": "да",
    "non": "нет"
  },
  "questions": [
    {
      "id": "riMalheur",
      "question": "Вы смеялись с чужого горя ?"
    },
    {
      "id": "riMental",
      "question": "Вы смеялись над кем-то с психическими или физическими недостатками/расстройствами ?"
    },
    {
      "id": "poserLapin",
      "question": "Не приходили ли Вы уже на встречу, не предупреждая об этом ?"
    },
    {
      "id": "foisArrete",
      "question": "Сколько раз Вы были арестованы ?"
    },
    {
      "id": "gouterAlcool",
      "question": "Вы пробовали алкоголь ?"
    },
    {
      "id": "dejaIvre",
      "question": "Бывали ли Вы пьяным/бухим ?"
    },
    {
      "id": "ivre48h",
      "question": "Были ли Вы пьяны более 48 часов ?"
    },
    {
      "id": "tomberIvre",
      "question": "Вы уже падали из-за того, что были очень пьяными ?"
    },
    {
      "id": "pisserDessus",
      "question": "Вы когда-нибудь мочились на себя, когда были пьяны ?"
    },
    {
      "id": "participerBoire",
      "question": "Вы когда-нибудь участвовали в играх на выпивку ?"
    },
    {
      "id": "buVomir",
      "question": "Вы уже напивались так, что рыгали ?"
    },
    {
      "id": "vomiQuelquun",
      "question": "Бывало ли такое, что Вы рыгали на себя или на кого-то другого ?"
    },
    {
      "id": "virerNuit",
      "question": "Вас уже выгоняли из бара или клуба не по собственному желанию ?"
    },
    {
      "id": "barathon",
      "question": "Вы уже участвовали в барафоне ? (Выпить во всех барах города или улицы) ?"
    },
    {
      "id": "alcoolDejeuner",
      "question": "Пили ли Вы алкоголь на завтрак (похмелялись) ?"
    },
    {
      "id": "alcool3fois",
      "question": "Употребляете ли Вы алкоголь как минимум 3 раза в неделю ?"
    },
    {
      "id": "jourPlus",
      "question": "В дополнение к тем 3 дням, сколько еще дней сверху Вы пьете ?"
    },
    {
      "id": "dormiToilette",
      "question": "Спали ли Вы уже в туалете ?"
    },
    {
      "id": "volontaireToilette",
      "question": "...Вы сами захотели спать в этом месте ?"
    },
    {
      "id": "evanouiBar",
      "question": "Было ли такое, что Вы уже засыпали или вырубались в баре ?"
    },
    {
      "id": "avalerVomi",
      "question": "Глотали ли Вы свою или чужую рвоту ?"
    },
    {
      "id": "vomiLieuPublic",
      "question": "Ригалы ли Вы в публичном месте ?"
    },
    {
      "id": "urinerPasPrevu",
      "question": "Писали ли Вы в месте не предназначенном для этого ?"
    },
    {
      "id": "essayerDrogue",
      "question": "Вы когда-нибудь пробовали наркотики ?"
    },
    {
      "id": "drogueEssaye",
      "question": "Сколько видов наркотиков Вы уже принимали ?"
    },
    {
      "id": "defonce48h",
      "question": "Были ли Вы под наркотой более 48 часов ?"
    },
    {
      "id": "drogueRegulier",
      "question": "Употребляете ли Вы часто наркотики ?"
    },
    {
      "id": "plus4fois",
      "question": "Более 4 раз в неделю ?"
    },
    {
      "id": "acheteDrogue",
      "question": "Вы уже покупали наркотики ?"
    },
    {
      "id": "venduDrogue",
      "question": "Вы уже продавали наркотики ?"
    },
    {
      "id": "venduDrogueFinance",
      "question": "Продавали ли Вы уже наркотики, чтобы самому же иметь возможность их употреблять ?"
    },
    {
      "id": "badTrip",
      "question": "У вас уже был 'bad trip' ?"
    },
    {
      "id": "initierDrogue",
      "question": "Вы уже предлагали кому-то наркотики ?"
    },
    {
      "id": "profiterQuelquun",
      "question": "Было ли такое, что Вы пользовались кем-то, когда он был пьян, под наркотиками или был беззащитным ?"
    },
    {
      "id": "sextape",
      "question": "Вы когда-нибудь снимали секс-видео ?"
    },
    {
      "id": "rdvAmoureux",
      "question": "Бывали ли вы уже на свидании ?"
    },
    {
      "id": "embrasseQuelquun",
      "question": "Вы уже кого-то целовали или ласкали ?"
    },
    {
      "id": "embrasserOrgasme",
      "question": "Вы уже доводили кого-то до оргазма своими ласками или поцелуями ?"
    },
    {
      "id": "relationsSexuelles",
      "question": "У Вас уже был секс ?"
    },
    {
      "id": "payerSexe",
      "question": "Платили ли Вы когда-нибудь за секс ?"
    },
    {
      "id": "sexeArgent",
      "question": "Спали ли Вы за деньги (секс имеется в виду) ?"
    },
    {
      "id": "impliqueFellation",
      "question": "Делали ли Вам или Вы кому-то минет / куннилингус ?"
    },
    {
      "id": "jusquaOrgasme",
      "question": "Вплоть до оргазма ?"
    },
    {
      "id": "rapportAnal",
      "question": "Был ли у Вас опыт анального секса?"
    },
    {
      "id": "jusquaOrgasme2",
      "question": "Вплоть до оргазма ?"
    },
    {
      "id": "fait69",
      "question": "Был ли у Вас опыт с позой 69 ?"
    },
    {
      "id": "ist",
      "question": "Болели ли Вы уже болезнями, которые передаются половым путем ?"
    },
    {
      "id": "sansContraception",
      "question": "Занимались ли Вы уже сексом без средств контрацепции ?"
    },
    {
      "id": "avortement",
      "question": "Делали ли Вы либо Ваш партнер аборт ?"
    },
    {
      "id": "plusDunePersonne",
      "question": "Был ли у Вас секс в трех (групповуха) ?"
    },
    {
      "id": "plusDeuxPersonneSemaine",
      "question": "Был ли у Вас секс в трех (групповуха) несколько раз в течение короткого промежутка времени ?"
    },
    {
      "id": "sexeLieuPublic",
      "question": "Занимались Вы сексом в общественном месте ?"
    },
    {
      "id": "blessureSexe",
      "question": "Были ли Вы у Вас травмы  из за секса ?"
    },
    {
      "id": "homo",
      "question": "Имели ли Вы опыт гомосексуальных половых отношений ?"
    },
    {
      "id": "bdsm",
      "question": "Имели Вы уже сексуальный опыт садомазо или бондажем ?"
    },
    {
      "id": "sextoys",
      "question": "Пользуетесь ли Вы секс игрушки ?"
    },
    {
      "id": "endormiSexe",
      "question": "Вы засыпали или теряли сознание во время секса ?"
    },
    {
      "id": "perteVirginite",
      "question": "Вы уже помогли кому то потерять девственность ?Если да, то скольким ?"
    },
    {
      "id": "acheterSexShop",
      "question": "Вы уже покупали что то в секс-шопе ?"
    },
    {
      "id": "sexeFamilial",
      "question": "Был ли у Вас секс с кем-то из Вашей семьи / родственником ?"
    },
    {
      "id": "hesiter",
      "question": "...Затруднились ли Вы ответить на предыдущий вопрос ?"
    },
    {
      "id": "zoophilieToussa",
      "question": "Вас привлекает или возбуждает зоофилия, некрофилия или педофилия ?"
    },
    {
      "id": "coupSoir",
      "question": "Был ли у Вас секс на одну ночь ?"
    },
    {
      "id": "combienCoupSoir",
      "question": "Сколько раз ?"
    },
    {
      "id": "sexeSansAurevoir",
      "question": "Был ли у Вас секс на одну ночь, и Вы потом уходили даже не прощаясь с партнером ?"
    },
    {
      "id": "sexeMemeFamille",
      "question": "Спали ли Вы с кем-то с одной и той же семьи (не обязательно одновременно) ?"
    },
    {
      "id": "sexeDejaRelation",
      "question": "Спали ли Вы уже с кем-то кто был помолвлен или имел отношения с кем-то другим ?"
    },
    {
      "id": "sexeMarie",
      "question": "Был он/она женат ?"
    },
    {
      "id": "sexeAmiPartenaire",
      "question": "Имели ли Вы уже секс с другом Вашего партнера ?"
    },
    {
      "id": "sexeDeuxFoisVieux",
      "question": "Имели ли Вы уже секс с кем-то кто в два раза старше Вас ?"
    },
    {
      "id": "bainMembreOppose",
      "question": "Принимали ли Вы ванну или душ с кем-то из противоположного пола?"
    },
    {
      "id": "lecherOeil",
      "question": "Вы лизали кому глаз, ухо или пальцы ног ?"
    },
    {
      "id": "dejaMasturbation",
      "question": "Вы дрочили себе ?"
    },
    {
      "id": "masturbationTelephone",
      "question": "Вы уже дрочили общаясь по телефону (секс по телефону) ?"
    },
    {
      "id": "masturbationDeux",
      "question": "Дрочка с кем то ?"
    },
    {
      "id": "doucheDoree",
      "question": "Участвовали ли Вы в золотом дожде (Вы писали на кого-то или он/она на Вас) ?"
    },
    {
      "id": "reveErotique",
      "question": "Есть ли у Вас своя эротическая/секс мечта ?"
    },
    {
      "id": "voyeur",
      "question": "Получали ли Вы уже наслаждение от наблюдения за сексом других ?"
    },
    {
      "id": "simulerOrgasme",
      "question": "Симулировали ли Вы оргазм ?"
    },
    {
      "id": "sortiSexe",
      "question": "Ходили ли Вы на свидание с кем-то только ради секса ?"
    },
    {
      "id": "sexePari",
      "question": "Имели ли Вы уже секс только ради того, чтобы выиграть какой-то спор ?"
    },
    {
      "id": "sexeCoupDeVent",
      "question": "Имели ли Вы уже секс на скорую руку (в обед, на улице, внезапно ...) ?"
    },
    {
      "id": "sexePromotion",
      "question": "Имели ли Вы уже секс, чтобы получить роботу или повышение ?"
    },
    {
      "id": "intimePublic",
      "question": "Выставляли ли Вы свои половые прелести на публику ?"
    },
    {
      "id": "indelite",
      "question": "Изменяли ли Вы ?"
    },
    {
      "id": "sexePartenaireAmi",
      "question": "Имели Вы уже секс с партнером Вашего друга ?"
    },
    {
      "id": "sexeRegret",
      "question": "Имели ли Вы уже секс о котором сейчас жалеете ?"
    },
    {
      "id": "telephoneSexe",
      "question": "Отвечали ли Вы на телефонный звонок или сами звонили во время секса ?"
    },
    {
      "id": "teleSexe",
      "question": "Смотрели ли Вы телевизор во время секса ?"
    },
    {
      "id": "dessinAnimeSexe",
      "question": "Задумывались ли Вы о половой жизни персонажей из аниме ?"
    },
    {
      "id": "ouSuisJe",
      "question": "Просыпались ли Вы в неизвестном месте и спрашивали кого-то где Вы ?"
    },
    {
      "id": "ouSuisJeAvecQui",
      "question": "Просыпались ли Вы в неизвестном месте и спрашивали того, кто был возле Вас где Вы и кто он такой ?"
    },
    {
      "id": "tatouer",
      "question": "Есть ли у Вас тату ?"
    },
    {
      "id": "travaillerCaritative",
      "question": "Вы когда-нибудь работали волонтером (на благотворительность) ?"
    },
    {
      "id": "possedeBible",
      "question": "У Вас есть Библия ?"
    },
    {
      "id": "donCaritative",
      "question": "Вы давали деньги на благотворительность ?"
    },
    {
      "id": "lieuCulte",
      "question": "Вы бывали в церкви / мечети / синагоге, кроме того момента , когда были на свадьбе или похоронах ?"
    },
    {
      "id": "lieuCulteUneSemaine",
      "question": "Ходите ли Вы в церковь больше чем раз в неделю ?"
    },
    {
      "id": "ptitfilou",
      "question": "Делали ли Вы что-то из этого теста специально чтобы улучшить Вашу оценку ?"
    }
  ],
  "results": {
    "global": {
      "you-are": "Вы",
      "your-score-is": "Вы набрали  -",
      "points": "баллов",
      "reponses-positives": "Положительные ответы",
      "moyenne-line": "Оценка может быть в диапазоне от {minScore} до {maxScore}, средний балл тестов- {moyenne} (серед {testsNumber} пройдених тестів)"
    },
    "purity-levels": {
      "tres-pur": "ОЧЕНЬ ПРАВИЛЬНЫЙ",
      "pur": "СВЯТОША",
      "plutot-pur": "ЧИСТЫЙ",
      "impur": "ГРЯЗНЫЕ",
      "salement-impur": "НУ ОЧЕНЬ ГРЯЗНЫЕ",
      "dangereux-impur": "ОПАСНЫЕ",
      "ignoble-impur": "МАНЬЯК"
    },
    "category": {
      "sex": "Секс",
      "alcohol": "Алкоголь",
      "drugs": "Наркотик",
      "hygiene": "Гигиена",
      "moral": "Мораль",
      "positives": "Положительный"
    },
    "phrases": {
      "negatif": [
        "Не забудьте сумку для покупок на рынке после цекрвы в воскресенье ... ",
        " (Ваш балл является отрицательным - это означает, что Вы очень правильные или очень молоды, чтобы пройти этот тест) "
      ],
      "10": [
        "Для Вас две красивые сестрички это еще извращение!",
        "(Вы очень правильный и похода без опыта, возможно, через свой выбор или незрелость;-))"
      ],
      "50": [
        "Перестаньте выживать и начните жить! ",
        " (Делайте то, что Вы хотите, главное никогда ни о чем не жалеть!) "
      ],
      "100": [
        "Дааа, Вы используете свою правую (может и левую) руку, чтобы удовлетворить себя, как и все остальные! ",
        " (Средний стандартный балл, ничего удивительного!) "
      ],
      "150": [
        "Немного выше среднего бала.Все, что можем Вам сказать, что Вы выходите тусить только несколько раз в месяц! ",
        " (Неплохое начало! Вам не нужно никого стесняться!) "
      ],
      "200": [
        "Жизнь для Вас это просто огромная игровая площадка! ",
        " (Ваше прошлое было полно сюрпризов, и оно еще не закончилось, и еще далеко до этого!) "
      ],
      "250": [
        "Вы живете на полную, без боязни о завтрашнем дне! ",
        " (Оценка, которая дает Вам право заявить о Вашей плохой стороне: P) "
      ],
      "400": [
        "Оооо да .... Примерьте эти белую смирительную рубашку, и все будет хорошо! Люди в белых халатах позаботятся о тебе ",
        " (Делайте в жизни все, что Вы хотите! Главное - никогда ни о чем не жалейте!) "
      ],
      "max": [
        "Ну ты походу животное, а не человек! Как бы Вы могли лизать свох генаталии, то Вы бы из дома не выходили ",
        "(Ебаный маньяк!)"
      ]
    },
    "twitter": "J'ai fait {score} points au Test de Pureté #griffor ! Et toi ? :)"
  }
}
